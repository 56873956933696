import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { env } from '@/utils'
import KeycloakMiddleware from '@/plugins/keycloak-middleware'
import { isArray } from 'chart.js/helpers'
import { Currency } from '@/types'
import { BigNumber } from 'bignumber.js'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Homepage',
		component: () => import(/* webpackChunkName: "homepage" */ '../views/Homepage.vue'),
		meta: {
			layout: 'public',
		},
	},
	{
		path: '/accounts/:currency/:type',
		name: 'Account',
		props: true,
		component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
	},
	{
		path: '/dashboard',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
	},
	{
		path: '/buyback',
		name: 'BuybackBurn',
		props: false,
		component: () => import(/* webpackChunkName: "buybackBurn" */ '../views/BuybackBurn.vue'),
	},
	// {
	// 	path: '/checkout',
	// 	name: 'Checkout',
	// 	props: route => ({
	// 		intentId: route.query.intentId,
	// 	}),
	// 	component: () => import(/* webpackChunkName: "checkoutView" */ '../views/CheckoutView.vue'),
	// 	meta: {
	// 		menu: false,
	// 	},
	// },
	// {
	// 	path: '/checkout/confirm/:intentId',
	// 	name: 'CheckoutConfirm',
	// 	props: route => ({
	// 		intentId: route.params.intentId,
	// 		status: route.query.Status,
	// 		lang: route.query.lang,
	// 	}),
	// 	component: () => import(/* webpackChunkName: "checkoutConfirm" */ '../views/CheckoutConfirm.vue'),
	// 	meta: {
	// 		menu: false,
	// 	},
	// },
	// {
	// 	path: '/checkout/cancel/:intentId/:type/:lang',
	// 	name: 'CheckoutCancel',
	// 	props: route => ({
	// 		intentId: route.params.intentId,
	// 		lang: route.params.lang,
	// 		type: route.params.type,
	// 	}),
	// 	component: () => import(/* webpackChunkName: "checkoutCancel" */ '../views/CheckoutCancel.vue'),
	// 	meta: {
	// 		menu: false,
	// 	},
	// },
	// {
	// 	path: '/checkout/confirm/:intentId/:type/:lang',
	// 	name: 'CheckoutConfirm',
	// 	props: route => ({
	// 		intentId: route.params.intentId,
	// 		lang: route.params.lang,
	// 		status: route.query.Status,
	// 		type: route.params.type,
	// 	}),
	// 	component: () => import(/* webpackChunkName: "checkoutConfirm" */ '../views/CheckoutConfirm.vue'),
	// 	meta: {
	// 		menu: false,
	// 	},
	// },
	{
		path: '/confstatus',
		name: 'ConfStatus',
		props: false,
		component: () => import(/* webpackChunkName: "confStatus" */ '../views/ConfStatus.vue'),
		meta: {
			menu: false,
		},
	},
	{
		path: '/explorer/:currency',
		name: 'Explorer',
		props: route => ({
			currency: route.params.currency as Currency,
			accountId: route.query.accountId,
			date: route.query.date,
			offset: route.query.offset !== null && typeof route.query.offset === 'string' ? Number.parseInt(route.query.offset) : undefined,
			size: route.query.size !== null && typeof route.query.size === 'string' ? Number.parseInt(route.query.size) : undefined,
		}),
		component: () => import(/* webpackChunkName: "explorer" */ '../views/Explorer.vue'),
	},
	// {
	//  	path: '/ico',
	//  	name: 'ICO',
	//  	props: false,
	//  	component: () => import(/* webpackChunkName: "launchpad" */ '../views/Launchpad.vue'),
	// },
	{
		path: '/ky',
		name: 'KY',
		component: () => import(/* webpackChunkName: "ky" */ '@/views/KY.vue'),
		meta: {
			layout: 'empty',
		},
	},
	{
		path: '/kyc',
		redirect: to => {
			return {
				path: '/ky',
			}
		},
	},
	// {
	// 	path: '/goat',
	// 	name: 'goat',
	// 	props: false,
	// 	component: () => import(/* webpackChunkName: "goat" */ '../views/Goat.vue'),
	// },
	{
		path: '/logout',
		name: 'Logout',
		props: false,
		component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
	},
	{
		path: '/longdeposit',
		name: 'LongDeposit',
		props: false,
		component: () => import(/* webpackChunkName: "longdeposit" */ '../views/LongDeposit.vue'),
	},
	{
		path: '/longdeposit/:currency',
		name: 'LongDepositAccount',
		props: true,
		component: () => import(/* webpackChunkName: "longdepositaccount" */ '../views/LongDepositAccount.vue'),
	},
	// {
	// 	path: '/magicbarn',
	// 	name: 'MagicBarn',
	// 	props: false,
	// 	component: () => import(/* webpackChunkName: "magicbarn" */ '../views/MagicBarn.vue'),
	// },
	{
		path: '/myaccount',
		name: 'MyAccount',
		component: () => import(/* webpackChunkName: "myaccount" */ '../views/MyAccount.vue'),
	},
	{
		path: '/otc',
		name: 'otc',
		props: route => ({
			buyer: route.query.buyer,
			seller: route.query.seller,
			productCurrency: route.query.productCurrency,
			productType: route.query.productType,
			unitPriceCurrency: route.query.unitPriceCurrency,
			unitPriceType: route.query.unitPriceType,
		}),
		component: () => import(/* webpackChunkName: "ftmarketplace" */ '../views/OTC.vue'),
	},
	// {
	// 	path: '/public/ico',
	// 	name: 'PublicICO',
	// 	props: false,
	// 	component: () => import(/* webpackChunkName: "publicico" */ '../views/Launchpad.vue'),
	// 	meta: {
	// 		layout: 'public',
	// 	},
	// },
	{
		path: '/public/explorer',
		name: 'PublicExplorer',
		props: route => ({
			currency: undefined,
			accountId: route.query.accountId,
			date: route.query.date,
			offset: route.query.offset !== null && typeof route.query.offset === 'string' ? Number.parseInt(route.query.offset) : undefined,
			size: route.query.size !== null && typeof route.query.size === 'string' ? Number.parseInt(route.query.size) : undefined,
		}),
		component: () => import(/* webpackChunkName: "publicexplorer" */ '../views/Explorer.vue'),
		meta: {
			layout: 'public',
		},
	},
	// {
	// 	path: '/public/spot',
	// 	name: 'PublicSpot',
	// 	props: false,
	// 	component: () => import(/* webpackChunkName: "publicspot" */ '../views/Spot.vue'),
	// 	meta: {
	// 		layout: 'public',
	// 	},
	// },
	// {
	// 	path: '/public/spot/:pair',
	// 	name: 'PublicSpotPair',
	// 	props: true,
	// 	component: () => import(/* webpackChunkName: "publicspotpair" */ '../views/SpotPair.vue'),
	// 	meta: {
	// 		layout: 'public',
	// 	},
	// },
	// {
	// 	path: '/spot',
	// 	name: 'Spot',
	// 	props: false,
	// 	component: () => import(/* webpackChunkName: "spot" */ '../views/Spot.vue'),
	// },
	// {
	// 	path: '/spot/:pair',
	// 	name: 'SpotPair',
	// 	props: true,
	// 	component: () => import(/* webpackChunkName: "spotpair" */ '../views/SpotPair.vue'),
	// },
	{
		path: '/staking',
		name: 'Staking',
		props: false,
		component: () => import(/* webpackChunkName: "staking" */ '../views/Staking.vue'),
	},
	{
		path: '/termdeposit',
		name: 'Term deposit',
		props: false,
		component: () => import(/* webpackChunkName: "termdeposit" */ '../views/TermDeposit.vue'),
	},
	{
		path: '/4dev',
		name: '4dev',
		props: false,
		component: () => import(/* webpackChunkName: "4dev" */ '../views/V4dev.vue'),
	},
	{
		path: '*',
		name: 'InvalidURL',
		redirect: to => {
			// the function receives the target route as the argument
			// a relative location doesn't start with `/`
			// or { path: 'profile'}
			return '/'
		},
	},
]

if (env('VUE_APP_ENV') === 'staging') {
	// for alpha testing
	routes.push(
		{
			path: '/internal',
			name: 'Internal',
			props: false,
			component: () => import(/* webpackChunkName: "internal" */ '../views/Internal.vue'),
		}
	)
}

if (env('VUE_APP_ENV') === 'staging') {
	// for beta testing
	routes.push(
		{
			path: '/apps',
			name: 'Apps',
			props: false,
			component: () => import(/* webpackChunkName: "apps" */ '../views/Apps.vue'),
		},
	)
}

if (env('VUE_APP_ENV') === 'prod') {
	// routes.push()
}

const middlewareLoop = (route: RouteConfig) => {
	if (route.meta === undefined) {
		route.meta = {}
	}

	if (route.meta.layout !== 'public') {
		route.beforeEnter = KeycloakMiddleware
	}

	if (isArray(route.children)) {
		for (const childRoute of route.children) {
			middlewareLoop(childRoute)
		}
	}
}

for (const route of routes) {
	middlewareLoop(route)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
